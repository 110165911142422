export default {
  BASE_URL: "https://stemclass-backend-old.azurewebsites.net", //"https://stemclass-backend-fastapi-taexr.ondigitalocean.app", //https://stemclass-backend-fastapi-taexr.ondigitalocean.app, http://localhost:8000/
  // AKS_RWRITE_URL: "http://localhost:8001",
  // AKS_RREAD_URL: "http://localhost:8002",
  
  AKS_RWRITE_URL: "https://stemclass.steamcube.in/bemain",
  AKS_RREAD_URL: "https://stemclass.steamcube.in/besecond",
  AKS_FWRITE_URL: "http://localhost:8003",
  AKS_FREAD_URL: "http://localhost:8004",

  SPACES_END_POINT_URL:
    "https://stemclass-prod-v2.fra1.cdn.digitaloceanspaces.com/files/",
  GUEST: {
    SCHOOL_ACCOUNT_CREATION: "/school/create-account",
    CHANGE_USER_PASSWORD: "rwrite/api/changepassword",
    FORGOT_USER_PASSWORD: "rwrite/api/forgotpassword",
    EDIT_USER_PROFILE: "rwrite/api/editprofile",
    SET_USER_PASSWORD: "/auth/set-password",
    LOGIN: "rwrite/api/gettoken",
    // LOGIN: "/auth/login",
    REFRESH: "rwrite/api/refreshtoken",
    GET_USER_DETAILS: "rread/api/v1.0/getuserdetails",
    // GET_USER_DETAILS: "/auth/get-current-user",
    REGISTER_TEACHER: "/auth/create-teacher-account",
    VALIDATE_CLASS_CODE: "/auth/validate-class-code",
    ENROLL_STUDENT: "/auth/enroll-student",
    CREATE_STUDENT: "/auth/create-student",
    PROFILE_VIEW: "rread/api/v1.0/currlm/profileview"
  },
  SCHOOL: {
    INVITE_TEACHERS: "/school/invite-teachers",
    GET_TEACHER_LIST: "/school/get-teachers",
    ASSIGN_SUBJECTS: "/school/assign-teachers",
    GET_SUBJECT_ASSIGN: "/school/get-teacher-mappings",
    ASSIGN_CLASS_TEACHER: "/school/assign-class-teacher",
    GET_CLASS_TEACHERS: "/school/list-class-teacher-mapping",
    GET_USER_COUNT: "/school/get-users-count",
    GET_STUDENT_GENDER_COUNT: "school/get-student-gender-count",
    GET_DUE_DATA: "school/get-due-data",
    GET_CLASSES: "school/get-total-classes",
    GET_LEARNING_DATA_BY_SECTION: "school/get-learning-data-by-section",
  },
  PUBLIC: {
    // GET_SUBJECTS: "/public/syllabus/v1/get-subjects",
    GET_SUBJECTS: "rread/api/v1.0/currlm/getteachersubjects",
    GET_STANDARDS: "/public/syllabus/v1/get-classes",
    GET_STRANDS: "/public/syllabus/get-strands",
    GET_CHAPTERS: "/public/syllabus/get-chapters",
    // GET_TOPICS: "/public/syllabus/get-topics",
    GET_TOPICS: "rread/api/v1.0/currlm/gettopics",
    GET_LEARNING_OBJECTIVES: "/public/syllabus/get-learning-objectives",
    GET_LEARNING_OUTCOMES: "/public/syllabus/get-learning-outcomes",
    GET_LEARNING_OBJECTIVES_FROM_TOPIC:
      "/public/syllabus/get-learning-objectives-from-topic",
    UPLOAD_FILE: "/rwrite/api/createcurriculumcontent",
    // GET_DROPDOWN_LIST: "/public/syllabus/get-primary-dropdown-list",
    GET_DROPDOWN_LIST: "rread/api/v1.0/currlm/getdropdownlist",
    GET_DROPDOWN_VALUES: "/public/syllabus/get-dropdown-values",
    GET_SECONDARY_LIST: "/public/syllabus/get-secondary-list",
    GET_MAPING_INFO: "/public/syllabus/get-mapping-info",
    GET_TERITIARY_LIST: "/public/syllabus/get-tertiary-list",
    GET_TOPIC_DETAILS: "rread/api/v1.0/currlm/gettopicdetails",
    GET_TAB_LIST: "/public/syllabus/get-tabs-data",
    // GET_TAB_LIST: "/public/syllabus/get-tabs-data",
    SET_DEFAULT_SCHOOL: "rwrite/api/v1.0/currlm/setuserdefaultschool",
    SET_DEFAULT_ROLE: "rwrite/api/v1.0/currlm/setuserdefaultrole",
    AI_CHAT_BOT: "rread/api/v1.0/currlm/aichatbot",
  },
  TEACHER: {
    SEARCH_YOUTUBE: "rread/api/v1.0/currlm/searchyoutube",
    // CREATE_CONTENT: "/teacher/add-content",
    CREATE_CONTENT: "rwrite/api/v1.0/currlm/setcurriculumcontent",
    // LIST_CLASSES: "/teacher/list-classes",
    LIST_CLASSES: "rread/api/v1.0/currlm/getteacherclasses",
    // LIST_CONTENTS: "/teacher/list-contents",
    LIST_CONTENTS: "rread/api/v1.0/currlm/getcontentdetails",
    LIST_CHILD_LESSONS: "/teacher/list-child-lessons",
    GET_DASHBOARD_DATA: "/teacher/get-dashboard-data",
    DELETE_CONTENT: "/teacher/delete-content",
    LIST_CONTENTS_BY_LESSON_ID: "/teacher/list-contents-by-lesson-id",
    // LOG_TEACHING: "/teacher/log-teaching",
    LOG_TEACHING: "rwrite/api/v1.0/currlm/setteacherteachingsession",
    LOG_REVIEWING: "rwrite/api/v1.0/currlm/setteacherreviewingsession",
    // UPDATE_LESSON_PLAN: "/teacher/update-lesson-planning",
    UPDATE_LESSON_PLAN: "rwrite/api/v1.0/currlm/savelessonplanchaptertopic",
    GET_LESSON_PLAN: "/teacher/get-lesson-planning",
    // GET_LESSON_PREVIEW: "/teacher/get-lesson-preview",
    GET_LESSON_PREVIEW: "rread/api/v1.0/currlm/getclasspreview",
    GET_REVIEW_RESULTS: "rread/api/v1.0/currlm/getreviewresults",
    GET_MY_REVIEW_RESULTS: "rread/api/v1.0/currlm/getmyreviewresults",
    // GET_ASSIGNED_DIVISIONS: "/teacher/get-assigned-divisions",
    GET_ASSIGNED_DIVISIONS: "rread/api/v1.0/currlm/getteacherclassdivisions",
    GET_LESSON_COMPLETION_STATUS: "/teacher/get-lesson-completion-status",
    GET_AGGREGATED_REPORT:
      "/teacher/get-teacher-aggregated-report-for-curriculum",
    GET_STUDENT_AGGREGATE_REPORT: "/teacher/get-teacher-student-logs-report-for-curriculum",  
    GET_LEARNING_STATUS: "rread/api/v1.0/currlm/getteacherteachsession",
    GET_REVIEW_STUDENTS: "rread/api/v1.0/currlm/getreviewstudents",
    GET_LESSON_PLAN_CHAPTER_TOPIC: "rread/api/v1.0/currlm/getlessonplanchaptertopic",
  },
  STUDENT: {
    // LIST_SUBJECTS: "/student/list-subjects",
    LIST_SUBJECTS: "rread/api/v1.0/currlm/getstudentsubjects",
    LIST_LESSONS: "/student/list-lessons",
    // ADD_CONTENT: "/student/add-or-update-student-content",
    ADD_CONTENT: "rwrite/api/v1.0/currlm/addupdatestudentcontent",
    LOG_LEARNING: "rwrite/api/v1.0/currlm/setstudentlearnsession",
    // GET_LEARNING_STATUS: "/student/get-learning-status",
    GET_LEARNING_STATUS: "rread/api/v1.0/currlm/getstudentlearnsession",
    GET_CONTENT: "/student/get-existing-content",
    GET_STUDENT_ACTIVITY_FILE: "rread/api/v1.0/currlm/getstudentactivityfile",
  },
};
